<template>
  <form @submit.prevent="productsFilter" class="in-products-filter mb-5">
    <section class=" box-shaddow20 p-3 bg-white mb-3">
      <h6 class="mr-2 fontsize-medium weight-bold text-color-444 mt-3">
        عنوان
      </h6>
      <input
        type="text"
        v-model="filter.title"
        placeholder="عنوان جستجو را بنویسید"
        class=" py-2 px-3 border   w-100 fontsize-small"
      />
    </section>
    <section
      v-if="sizeFilters && sizeFilters.length"
      class="  box-shaddow20 p-3 bg-white mb-3"
    >
      <div class="text-right border-bottom mb-3">
        <h6 class="mr-2 fontsize-medium weight-bold text-color-444 mt-3">
          سایز
        </h6>
      </div>
      <div class="d-flex flex-wrap">
        <div
          class="size-item"
          v-for="(size, index) in sizeFilters"
          :key="index"
        >
          <label
            class="text-color-666  d-flex align-items-center"
            v-if="size"
            style="line-height:1.9"
          >
            <span class="fontsize-small">{{ size.value }}</span>
            <b-form-checkbox
              v-model="filter.selected_sizes[index]"
              class="size-label"
              :unchecked-value="false"
            />
          </label>
          <b-skeleton v-else class="ml-2 d-inline-block size-skeleton" />
        </div>
      </div>
    </section>
    <!--    <section v-if="!colors || (colors && colors.length)" class="  box-shaddow20 p-3 bg-white mb-3">-->
    <!--      <div class="text-right border-bottom mb-3">-->
    <!--        <h6 class="mr-2 fontsize-medium weight-bold text-color-444 mt-3">رنگ</h6>-->
    <!--      </div>-->
    <!--      <div class="d-flex flex-wrap">-->
    <!--        <div v-if="colors && filter && filter.color_ids">-->
    <!--          <treeselect-->
    <!--              :searchable="true"-->
    <!--              :multiple="true"-->
    <!--              :options="colors"-->
    <!--              noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"-->
    <!--              placeholder="انتخاب رنگ ها"-->
    <!--              v-model="filter.color_ids"-->
    <!--          >-->
    <!--            <label :style="'height: 40px'"-->
    <!--                   slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">-->
    <!--              <div class="color-option-img" :style="'background-color:' + node.raw.code"></div>-->
    <!--              {{ node.label }}-->
    <!--            </label>-->
    <!--          </treeselect>-->
    <!--        </div>-->
    <!--        <b-skeleton v-else />-->
    <!--      </div>-->
    <!--    </section>-->
    <section class=" box-shaddow20 p-3 bg-white mb-3">
      <div class="text-right border-bottom pb-2">
        <h6 class="mr-2 fontsize-medium weight-bold text-color-444">
          حالت نمایش
        </h6>
      </div>
      <!-- <div class="mt-3 py-2 border-bottom d-flex">
        <label class="switch ">
          <input type="checkbox" name="x1">
          <span class="slider round"></span>
        </label>
        <span class="fontsize13 text-color-666 mr-2">
          فقط کالاهای اصل
        </span>
      </div> -->
      <div class="mt-3 py-2 d-flex">
        <label class="switch">
          <input type="checkbox" name="x1" v-model="filter.available" />
          <span class="slider round"></span>
        </label>
        <span class="fontsize-small text-color-666 mr-2">
          فقط کالاهای موجود
        </span>
      </div>
    </section>
    <section class=" box-shaddow20 p-3 bg-white mb-3">
      <h6 class="mr-2 fontsize-medium weight-bold text-color-444">قیمت ها</h6>
      <!-- <div class="price-filter-slidre w-100 bg-color-ea rounded-pill my-3">
        <div class="d-flex rounded-pill">
          <a class="rounded-circle"></a>
          <div></div>
          <a class="rounded-circle"></a>
        </div>
      </div> -->
      <vue-slider
        class=" mx-2 my-4"
        ref="slider"
        v-model="priceRange"
        :tooltip="'none'"
        :max="minMax ? minMax : 50000"
        :interval="step"
        direction="rtl"
      >
      </vue-slider>
      <div class="fontsize-mini d-flex flex-wrap justify-content-between">
        <div class="ml-3">
          <span class="text-color-444 ml-1">از قیمت:</span>
          <span class="text-color-666" v-if="priceRange[0] != 0"
            >{{ priceRange[0] | price }} تومان</span
          >
          <span class="text-color-666" v-else>0 تومان</span>
        </div>
        <div>
          <span class="text-color-444 ml-1">تا</span>
          <span class="text-color-666">{{ priceRange[1] | price }} تومان</span>
        </div>
      </div>
    </section>
    <button
      type="submit"
      :disabled="loading || disabled"
      class="fontsize-small weight-bold text-white bg-color-theme py-2 px-3 w-100"
    >
      اعمال فیلتر
    </button>
  </form>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import { Treeselect } from "@riophae/vue-treeselect";
import { BSkeleton, BFormCheckbox } from "bootstrap-vue";

export default {
  name: "ProductsFilter",
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Treeselect,
    BSkeleton,
    BFormCheckbox,
  },
  data() {
    return {
      priceRange: [0, 50000],
      step: 1000,
      filter: cloneDeep(this.value),
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        this.filter = cloneDeep(newVal);
      },
      deep: true,
    },
    minMax(newVal) {
      this.$set(this.priceRange, 1, newVal);
    },
  },
  computed: {
    disabled() {
      return this.$store.getters["front/getProductsStatus"];
    },
    minMax() {
      return this.$store.getters["front/getProductsMaxPrice"];
    },
    sizeFilters() {
      if (this.$store.getters["front/getSizeValues"]) {
        let values = this.$store.getters["front/getSizeValues"].values;
        let filters = ["XXS","XXL","XXXL","XXXXL","XXXXXL","XXXXXXL","XXXXXXXL","XXXXXXXXL","0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29",
          "30",
        ];
        return values.filter((value)=>{return !filters.includes(value.value)})
      } else {
        return Array(6).fill(null);
      }
    },
    colors() {
      return this.$store.getters["front/getColors"]
        ? this.$store.getters["front/getColors"]
        : null;
    },
  },
  created() {
    if (this.minMax) {
      this.$set(this.priceRange, 1, this.minMax);
    }
  },
  methods: {
    productsFilter() {
      this.filter.page = 1;
      this.filter.max_price = this.priceRange[1];
      this.filter.min_price = this.priceRange[0];
      this.filter.available
        ? (this.filter.available = 1)
        : (this.filter.available = 0);
      this.$emit("input", this.filter);
    },
  },
};
</script>
<style scoped>
.color-option {
  background: black;
}
.color-option-img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}

.size-label {
  display: inline;
  margin-right: 2px;
}

.size-item {
  /* display: inline; */
  margin-left: 5px;
}

.size-skeleton {
  width: 35px;
}
</style>
<style>
.vue-slider-dot {
  cursor: pointer;
}

.in-products-filter
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.13rem rgb(222 222 222);
}

.in-products-filter
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #a07f5c;
  background-color: #121314;
}
</style>
