<template>
  <div
      class="modal fade mobile-friendly"
      :id="id"
      tabindex="-1"
      :aria-labelledby="id"
      aria-hidden="true"
  >
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content p-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { setCookie, getCookie } from "@/libraries/VueMethods";

export default {
  name: "WarningPopup",
  props: {
    id: String,
  },
  mounted() {
    if (!getCookie(this.id) && this.$route.query.sort && this.$route.query.sort.includes('discount')) {
      setCookie(this.id, 1)
      setTimeout(() => {
        window.$('#' + this.id).modal('show')
      }, 400)
    }
  }
}
</script>

<style scoped>

</style>
