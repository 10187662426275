<template>
  <div @click="showDropDown" class="my-dropdown">
    <div :style="buttonContainerStyles" class="dropdown-button-container">
      <slot name="button"></slot>
    </div>
    <transition name="fast-fade">
      <div :style="dropdownStyles" class="dropdown-container" v-show="show_dropdown">
        <slot v-show="show_dropdown" name="dropdown"></slot>
        <div class="close-btn">
          <CloseSvg width="25" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CloseSvg from "@/components/svgs/CloseSvg";
export default {
  name: "Dropdown",
  components: {CloseSvg},
  props: {
    buttonContainerStyles: {
      type: String,
      default: ''
    },
    dropdownStyles: {
      type: String,
      default: ''
    },
    id: String
  },
  data: function () {
    return {
      show_dropdown: false,
    }
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
    window.removeEventListener("click", this.outsideClicked);
  },
  methods: {
    showDropDown() {
      if (this.show_dropdown) {
        this.hide()
      } else {
        this.show()
      }
    },
    hide() {
      window.removeEventListener("click", this.outsideClicked);
      window.removeEventListener("popstate", this.checkBackButton);
      if (this.$route.fullPath.includes(this.id)) {
        this.$router.back();
      }
      this.show_dropdown = false
      this.$emit('hide')
    },
    show() {
      if (!this.$route.fullPath.includes(this.id)) {
        this.$router.push(this.$route.fullPath + '#' + this.id)
      }
      window.removeEventListener("popstate", this.checkBackButton);
      window.addEventListener("popstate", this.checkBackButton);
      this.show_dropdown = true
      setTimeout(() => {
        window.addEventListener("click", this.outsideClicked);
      })
    },
    checkBackButton() {
      if (!this.$route.fullPath.includes(this.id)) {
        this.hide()
      }
    },
    outsideClicked(e) {
      this.$router.back();
      if (this.$route.fullPath.includes(this.id)) {
        this.$router.replace(this.$route.fullPath.replace('#' + this.id, ''));
        this.$nextTick(() => {
          this.hide()
        })
      }
      // this.show_dropdown = false;
      // window.removeEventListener("click", this.outsideClicked);
    },
    hasClass(element, className) {
      let regex = new RegExp('\\b' + className + '\\b');
      do {
        if (regex.exec(element.className)) {
          return true;
        }
        element = element.parentNode;
      } while (element);
      return false;
    }
  }
}
</script>


<style>
.list-enter-active, .list-leave-active {
  transition: all 350ms;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.fast-fade-enter-active, .fast-fade-leave-active {
  transition: all 250ms;
}
.fast-fade-enter, .fast-fade-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(5px);
}
.close-btn {
  display: none;
}
.dropdown-button-container {
  height: 100%;
}
.dropdown-container {
  position: absolute;
  left: calc(50% + 180px);
  transform: translateX(-9%);
  z-index: 9999;
}

@media screen and (max-width: 768px){
  .dropdown-container {
    position: fixed;
    width: 100%;
    height: 100%;
    transform: unset!important;
    z-index: 100000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    overflow-y: scroll;
  }
  .close-btn {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.my-dropdown {
  position: relative;
}
</style>
