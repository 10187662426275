<template>
   <section id="homeHeader" class="position-relative mt-0 mt-lg-2">
    <section
      v-if="sliders"
      class="top-slider position-relative"
    >
      <div class="swiper-container h-100">
        <div class="swiper-wrapper">
          <div
            v-for="slider in sliders"
            :key="slider.id"
            class="swiper-slide bg-color-ea"
          >
            <div
              v-if="slider.description"
              class="
                top-slider-content
                d-flex
                justify-content-between
                container
                mx-auto
              "
            >
              <div class="text-right my-auto ml-3">
                <span
                  class="
                    top-header-discount
                    bg-color-themeRed
                    fontsize-mini
                    text-white
                    px-2
                  "
                  v-if="slider.custom_fields && slider.custom_fields.discount"
                  >{{ slider.custom_fields.discount }}</span
                >
                <h5
                  class="font-weight-bold fontsize-heavy mb-3 mt-sm-3 mt-2 text-color-444"
                >
                  {{ slider.title }}
                </h5>
                <p
                  class="
                    fontsize-medium
                    text-justify text-color-444
                    line-height2
                    d-lg-block d-none
                  "
                >
                  {{ slider.description }}
                </p>
                <div>
                  <router-link
                    v-if="slider.linkable_type != null"
                    :to="findUrl(slider.linkable_type, slider.linkable_id)"
                  >
                    <button
                      type="button"
                      class="
                        top-slider-cart
                        text-white
                        bg-color-theme
                        fontsize-mini
                        weight-bold
                        py-md-2 py-1
                        px-md-3 px-2
                        ml-md-3 ml-2
                      "
                    >
                      خرید این محصول
                    </button>
                  </router-link>
                  <a v-else :href="slider.link">
                    <button
                      type="button"
                      class="
                        top-slider-cart
                        text-white
                        bg-color-theme
                        fontsize-mini
                        weight-bold
                        py-md-2 py-1
                        px-md-3 px-2
                        ml-md-3 ml-2
                      "
                    >
                      خرید این محصول
                    </button>
                  </a>

                  <router-link
                    v-if="slider.linkable_type != null"
                    :to="findUrl(slider.linkable_type, slider.linkable_id)"
                  >
                    <button
                      type="button"
                      class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-mini
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                    >
                      اطلاعات بیشتر ...
                    </button>
                  </router-link>

                  <a v-else :href="slider.link">
                    <button
                      type="button"
                      class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-mini
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                    >
                      اطلاعات بیشتر ...
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <DynamicLinker class="top-slider-img" :item="slider">
              <img
                :src="slider.image.url"
                class="w-100 h-100"
                :alt="slider.title"
              />
            </DynamicLinker>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="d-md-none swiper-pagination"></div>
        <!-- Add Arrows -->
      </div>
      <div class="d-md-block d-none swiper-button-next text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            fill="#999"
          />
        </svg>
      </div>
      <div class="d-md-block d-none swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            fill="#999"
          />
        </svg>
      </div>
    </section>
    <!-- <section class="d-flex justify-content-center container-md px-0">
      <ProductCategories></ProductCategories>
    </section> -->
   </section>

</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js";
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
// import SearchButton from "../header/SearchButton";
import { BSkeletonImg } from "bootstrap-vue";
import ProductCategories from "@/parts/Front/components/ProductCategories";

export default {
  name: "Header",
  components: {
    // SearchButton,
    DynamicLinker,
    BSkeletonImg,ProductCategories
  },
  watch: {
    sliders() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
  },
  inject: ["mediaQueries"],
  methods: {
    findUrl,
    getSliderCustomFields(slider) {
      return JSON.parse(slider.custom_fields);
    },
    allCategoriesClose() {
      this.$refs.respMenu.allCategoriesToggle();
    },
    onResize() {
      let topSliderH = document.querySelector(".top-slider");
      let bodyWidth = document.body.offsetWidth;
      if (topSliderH) {
        if (bodyWidth < 767) {
          topSliderH.style.height = bodyWidth / 2.2 + "px";

        } else {
          topSliderH.style.height = bodyWidth / 4 + "px";
        }
      }
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        new Swiper(".top-slider .swiper-container", {
          spaceBetween: 0,
          //   centeredSlides: true,
          breakpoints: {
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
              },
              768: {
              slidesPerView: 1,
              spaceBetween: 10,
              },
            570: {
              slidesPerView: 1.11,
              spaceBetween: 15,
              },
              320: {
              slidesPerView: 1.12,
              spaceBetween: 15,
              },
          },
          speed: 1000,
          loop: true,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".top-slider .swiper-button-next",
            prevEl: ".top-slider .swiper-button-prev",
          },
        });
      });
      this.$nextTick(() => {
        this.onResize();
      });
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",

          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout = true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout = false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              delete this.$axios.defaults.headers.common["Authorization"];
              this.$root.deleteCookie("Authorization");
              this.$store.commit("front/setLoginStatus", false);
            });
          }
        });
    },
  },
  mounted() {
    // ست کردن تنظیمات  برای اسکرول صفحه در برابر رسپانسیو
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
    this.$store.dispatch("front/getIndexSlider");
    this.initHeaderSwiper();
    //CHange height of top-slider
    const topSliderH = document.querySelector(".top-slider");
    const bodyWidth = document.body.offsetWidth;
    if (topSliderH) {
      if (bodyWidth < 767) {
        topSliderH.style.height = bodyWidth / 1.6 + "px";
      } else {
        topSliderH.style.height = bodyWidth / 2.2 + "px";
      }
    }

    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    // دریافت دیتا از سمت ویوایکس
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    sliders() {
      let slidersData = this.$store.getters["front/getSliderIndex"];
      // console.log(slidersData);
      if (slidersData) {
        let sliders;
        if (this.mediaQueries.medium) {
          sliders = slidersData.sliders.filter((s) =>
            s.group.includes("mobile")
          );
        } else {
          sliders = slidersData.sliders.filter(
            (s) => !s.group.includes("mobile")
          );
        }
        sliders.forEach((slider) => {
          try {
            slider.custom_fields = this.getSliderCustomFields(slider);
          } catch (e) {}
        });
        return sliders;
      }else{
        return null
      }
    },
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
  },

  data() {
    return {
      disabledlLogout: false,
    };
  },
};
</script>

<style scoped>
.swiper-container{
  width: 97%;
}
.swiper-slide .top-slider-img img {
  border-radius: 10px;
}
.log-btn {
  position: relative;
  background-color: black;
  min-width: 120px;
  /* border-top-left-radius: 25px 25px;
  border-top-right-radius: 25px 25px;
  border-bottom-right-radius: 25px 25px;
  border-bottom-left-radius: 25px 25px; */
  transition: 0.2s;
  color: white;
  padding: 8px 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media (max-width:600px){
  .swiper-container{
  width: 97%;
}
}
</style>
