<template>
  <section
    v-if="sets && sets.length"
    class="similar-products set-products  text-right container mb-5"
  >
    <div class="d-flex justify-content-between align-items-baseline mb-3">
      <h5 class="weight-bold fontsize-heavy">محصولات ست</h5>
    </div>
    <div class=" container sim-products px-0 pt-4 ">
      <div class=" recommended-content-item swiper-container">
        <div
          class="swiper-wrapper "
          v-if="sets != '' && !disabledSliders.includes('myProducts')"
        >
          <div
            class="swiper-slide px-1"
            v-for="(product, index) in sets"
            :key="index"
          >
            <Product :product="product"></Product>
          </div>
        </div>
      </div>
      <div class="swiper-pagination w-100 "></div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
import Product from "@/parts/Front/components/product/Product";
export default {
  props: {
    sets: Array,
  },
  components: {
    Product,
  },
  data() {
    return {
      disabledSliders: [],
    };
  },
  mounted() {
    this.initRelatedProducts();
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("myProducts", kill, () => {
        new Swiper(".similar-products .swiper-container", {
          spaceBetween: 30,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 1,
            },
            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
};
</script>
<style scoped>
.similar-products .swiper-container {
  overflow:  visible !important;
}
/* .similar-products .swiper-button-prev,.similar-products .swiper-button-next {
  width: 15px !important;
  height: 15px !important;
} */
.similar-products .container {
  overflow-x: hidden;
}

.sim-products {
  overflow: visible !important;
}
.similar-products .swiper-button-prev::after,
.similar-products .swiper-button-next::after {
  content: "unset" !important;
}
.similar-products .swiper-pagination-bullet {
  margin: 0 2px;
}
.similar-products .swiper-pagination {
  position: unset;
}
</style>
