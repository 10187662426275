<template>
  <div id="flashProduct" class="swiper-slide card text-right">
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
    >
      <img
        loading="lazy"
        v-if="product && mainImage"
        :src="getImage(mainImage, 'lg')"
        :alt="product.title"
        class="card-img-top img-fluid"
      />
      <div class="card-body">
        <h5
          class="card-title text-truncate text-dark fontsize-medium"
          :title="product.title"
        >
          {{ product.title }}
        </h5>
        <div class="d-flex align-items-center card-text">
          <p class="text-muted fontsize-medium m-0" v-if="discountAmount != 0">
            <DiscountSvg />
            <del>{{ (mainPrice + discountAmount) | price }}</del>
          </p>
          <p class="text-danger font-weight-bold fontsize-medium m-0">
            {{ mainPrice | price }} تومان
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import { getImage } from "@/libraries/functions";
import DiscountSvg from "@/parts/Front/components/svg/DiscountSvg.vue";
export default {
  name: "FlashProduct",
  components: { DiscountSvg },
  props: ["product"],
  methods: {
    getImage,
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.major_final_price.amount : null;
    },
    discountAmount() {
      return this.product
        ? this.product.major_final_price.discount_price
        : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
  },
};
</script>
<style scoped>
#flashProduct .card-text {
  gap: 15px;
}
#flashProduct .card-img-top {
  max-width: 100%;
  height: auto;
}

#flashProduct .card-title {
  max-height: 3.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  #flashProduct .card-title {
    max-height: 2.4rem;
  }
}
</style>
