<template>
  <div
    class="productFilterModal in-products-filter modal fade mobile-friendly"
    id="productOrderModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-header border-bottom-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="order-0 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-filter"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                fill="#999"
              />
            </svg>
            <span class="text-color-666 fontsize18 weight-bold pr-1 "
              >ترتیب نمایش:</span
            >
          </div>
        </div>
        <div class="modal-body p-0 pb-sm-5 pb-3">
          <section
            class="product-view-order d-flex justify-content-between align-items-baseline flex-wrap  bg-white box-shaddow20 p-3 pb-5 mb-3"
          >
            <div
              v-if="filter"
              class="d-flex flex-wrap justify-content-xl-between fontsize16 order-xl-1 order-2 mt-3"
            >
              <button
                type="button"
                :disabled="filter.sort == 'most_visited' || loading"
                :class="
                  $route.query.sort == 'most_visited' ? 'view-order-active' : ''
                "
                @click="(filter.sort = 'most_visited'), productsFilter()"
                class="m-1 py-2 px-3 text-color-999  "
              >
                پربازدید ترین
              </button>
              <button
                :disabled="filter.sort == 'low_to_high' || loading"
                :class="
                  $route.query.sort == 'low_to_high' ? 'view-order-active' : ''
                "
                @click="(filter.sort = 'low_to_high'), productsFilter()"
                type="button"
                class="m-1 py-2 px-3 text-color-999  "
              >
                ارزان ترین
              </button>
              <button
                :disabled="filter.sort == 'high_to_low' || loading"
                :class="
                  $route.query.sort == 'high_to_low' ? 'view-order-active' : ''
                "
                @click="(filter.sort = 'high_to_low'), productsFilter()"
                type="button"
                class="m-1 py-2 px-3 text-color-999  "
              >
                گران ترین
              </button>
              <button
                :disabled="filter.sort == 'top_sales' || loading"
                :class="
                  $route.query.sort == 'top_sales' ? 'view-order-active' : ''
                "
                @click="(filter.sort = 'top_sales'), productsFilter()"
                type="button"
                class="m-1 py-2 px-3 text-color-999  "
              >
                پرفروش ترین
              </button>
              <button
                :disabled="filter.sort == 'newest' || loading"
                :class="
                  $route.query.sort == 'newest' ? 'view-order-active' : ''
                "
                @click="(filter.sort = 'newest'), productsFilter()"
                type="button"
                class="m-1 py-2 px-3 text-color-999  "
              >
                جدید ترین
              </button>
              <button
                :disabled="filter.sort == 'most_discount' || loading"
                :class="
                  $route.query.sort == 'most_discount'
                    ? 'view-order-active'
                    : ''
                "
                @click="(filter.sort = 'most_discount'), productsFilter()"
                type="button"
                class="m-1 py-2 px-3 text-color-999  "
              >
                ویژه
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import closeModalMixin from '@/libraries/mixins/closeModalMixin'
export default {
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean,
    },
  },
    mixins: [closeModalMixin],
  data() {
    return {
      filter: cloneDeep(this.value),
      modals: ['productOrderModal'],
    };
  },
  watch: {
    value(newVal) {
      this.filter = cloneDeep(newVal);
    },
  },
  computed: {
    disabled() {
      return this.$store.getters["front/getProductsStatus"];
    },
  },
  methods: {
    productsFilter() {
      this.filter.page = 1;
      this.$("#productOrderModal").modal("hide");
      this.$emit("input", this.filter);
    },
  },
};
</script>
<style scoped>
    .view-order-active {
      color: var(--color-theme) !important;
      background-color: var(--color-theme2) !important;
    }
</style>
