<template>
  <div class="box-choose-img">
    <div class="d-flex">
      <button class="overflow-hidden mb-2" @click="select(option.id)"
              :class="isSelected(option.id) ? 'btn-dark selected' : 'btn-outline-dark'" v-for="option in options" :key="option">
        <div  v-if="option.variety && option.variety.images.length" class="product-box-choose-img">

          <img loading="lazy" :src="getImage(option.variety.images[0], 'md')">
        </div>
        <div v-else-if="option.label.charAt(0) !== '#'" class="product-box-choose-bottom text-center py-1 bg-box-theme text-box-666 weight-bold fontsize14">
          {{ option.label }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import {getImage} from '@/libraries/functions'

export default {
  name: "BoxImage",
  props: {
    options: Array, // id, label, idDisabled
    value: [String, Number]
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value() {
      this.realValue = this.value;
    }
  },
  methods: {
    getImage,
    select(value) {
      this.$emit('input', value)
      if (window.innerWidth < 768) {
        document.getElementById("pagePath").scrollIntoView({behavior: 'smooth'});
      }
    },
    isSelected(id) {
      return this.realValue === id;
    }
  }
}
</script>

<style scoped>
.box-choose-img > div {
  /*gap: 15px;*/
  padding: 4px;
  flex-wrap: wrap;
}

.box-choose-img::-webkit-scrollbar {
  width: 6px;
  background-box: var(--box-theme2);
}

.box-choose-img::-webkit-scrollbar-thumb {
  background-box: var(--box-theme);
}

.box-choose-img::-webkit-scrollbar-track {
  background-box: var(--box-theme2);
}

.product-details-box-choose button {
  width: 80px;
  height: 94px;
  text-align: center;
  margin-left: 10px;
  transition: .3s;
}

.product-details-box-choose button:hover {
  border: 1px solid var(--box-theme);
}

.product-details-box-choose button:hover .product-box-choose-bottom {
  background-box: var(--box-theme);
  box: white !important;
}

.product-box-choose-img {
  height: 100px;
  width: 100%;
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
}

.product-box-choose-img img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.product-box-choose-bottom {
  background-box: #dedede;
}

.box-choose-img {
  overflow-x: auto;
  scrollbar-box: var(--box-theme) var(--box-theme2);
  scrollbsr-width: thin;
}

.box-choose-img button {
  flex-shrink: 0;
  min-width: 95px;
  margin-left: 15px;
}

@media screen and (max-width: 455px) {
  .box-choose-img button {
    margin-left: 11px;
  }
}
@media screen and (max-width: 390px) {
  .box-choose-img button {
    margin-left: 8px;
    min-width: unset;
  }
}
@media screen and (max-width: 500px) and (min-width:421px) {
  .product-box-choose-img {
    height: 130px;
  }
}
@media screen and (max-width: 396px) {
  .product-box-choose-img {
    height: 115px;
  }
}
@media screen and (max-width: 366px) {
  .product-box-choose-img {
    height: 110px;
  }
}
@media screen and (max-width: 350px) {
  .product-box-choose-img {
    height: 105px;
  }
}
@media screen and (max-width: 330px) {
  .product-box-choose-img {
    height: 99px;
  }
}

.selected {
  box-shadow: 0 0 0 0.2rem #121314;
}
</style>
