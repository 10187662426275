<template>
  <div class="box-select-container">
    <button :disabled="option.isDisabled && !option.selectableAnyways" class="box-select-option btn"
         :class="[{disabled: option.isDisabled},isSelected(option.id) ? 'selected' : 'btn-outline-dark' + ' ' + getAdditionalClass(option)]"
         @click="select(option.id)"
         v-for="option in options">
      <span>{{ option.label }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BoxSelect",
  props: {
    options: Array, // id, label, idDisabled
    value: [String, Number]
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value() {
      this.realValue = this.value;
    }
  },
  methods: {
    select(value) {
      this.$emit('input', value)
    },
    isSelected(id) {
      return this.realValue === id;
    },
    getAdditionalClass(option) {
      switch (option.label.length) {
        case 7: return 'seven'
        case 6: return 'six'
        case 5: return 'five'
        case 4: return 'four'
        case 3: return 'three'
        case 2: return 'two'
        case 1: return 'one'
        default: return 'seven'
      }
    }
  }
}
</script>

<style scoped>
.box-select-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  @media screen  and (max-width: 768px){
    .box-select-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .box-select-option span {
    vertical-align: bottom;
    vertical-align: sub;
  }

  .box-select-option {
    min-width: 42px;
    margin-left: 5px;
    position: relative;
    border-radius: 0;
  }
  
  .box-select-option.disabled:after {
    content: ' ';
    background: url(../../../../../assets/images/redx.png);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 10;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    height: 100%;
    width: 100%;
  }
  /* .box-select-option.disabled:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -20%;
    width: 137%;
    height: 1.4px;
    background: red;
    transform: rotate(-45deg);
  }
  .box-select-option.disabled.four:before {
    left: -8%;
    width: 116%;
    transform: rotate(-30deg);
  }
  .box-select-option.disabled.four:after {
    left: -8%;
    width: 116%;
    transform: rotate(30deg);
  }

  .box-select-option.disabled.five:after {
    top: 49%;
    left: -8%;
    width: 115%;
    transform: rotate(-30deg);
  }
  .box-select-option.disabled.five:before {
    top: 49%;
    left: -8%;
    width: 115%;
    transform: rotate(30deg);
  }
  .box-select-option.disabled.three:before {
    top: 49%;
    left: -14%;
    width: 129%;
    transform: rotate(38deg);
  }
  .box-select-option.disabled.three:after {
    top: 49%;
    left: -14%;
    width: 129%;
    transform: rotate(-38deg);
  }
  .box-select-option.disabled.six:before {
    top: 49%;
    left: -6%;
    width: 112%;
    transform: rotate(-26deg);
  }
  .box-select-option.disabled.six:after {
    top: 49%;
    left: -6%;
    width: 112%;
    transform: rotate(26deg);
  }
  .box-select-option.disabled.seven:before {
    top: 48%;
    left: -5%;
    width: 110%;
    transform: rotate(-24deg);
  }
  .box-select-option.disabled.seven:after {
    top: 47%;
    left: -5%;
    width: 110%;
    transform: rotate(24deg);
  } */
  .selected {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
</style>
