<template>
    <div class="position-relative">
      <!-- <button
        @click="deleteItem()"
        :disabled="disabled"
        type="button"
        class="
          cart-item-close
          bg-none
          text-danger
          weight-bold
          position-absolute
          d-sm-block d-none
        "
        style="right: 0px; z-index: 100; top:40%"
        :style="mediaQueries.mobileSize ? 'padding: 0 0 10px 10px' : ''"
      >
       <svg
          width="25 "
          height="25  "
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
            fill="#ee1212"
          />
        </svg>
      </button> -->
      <div class=" pr-0 pt-2">
        <div class="d-flex justify-content-start align-items-center mb-1">
          <router-link
            :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
            class="cart-lists-img"
            style="max-width: 60px; height: 80px"
          >
            <img
              v-if="mainImage"
              :src="getImage(mainImage, 'md')"
              alt=""
              class="d-block"
            />
            <div v-else class="bg-cart-lists-img w-100 h-100"></div>
          </router-link>
          <div class="text-right text-color-444 w-100 pr-3">
            <router-link
              :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
              class="mb-1 fontsize-small weight-bold text-color-666 "
          >
            {{
              cartItem.variety
                  ? cartItem.variety.product.title.substr(0, 40)
                  : ""
              }}
              {{
                cartItem.variety && cartItem.variety.product.title.length > 40
                  ? "..."
                  : ""
            }}
          </router-link
          >
<h6 v-if="getVarietyTitle != null" class="text-color-a5 pt-1 fontsize13">{{ getVarietyTitle }}</h6>
          <div
              class="product-counter py-1 d-flex justify-content-between w-100 text-centertext-center border-radius7 my-auto"
          >
            <CartQuantity v-model="cartItem"/>
               <button
        @click="deleteItem()"
        :disabled="disabled"
        type="button"
        class="
          cart-item-close
          bg-none
          text-danger
          weight-bold
          pl-2
        "
        :style="mediaQueries.mobileSize ? 'padding: 0 0 10px 10px' : ''"
      >
       <svg
          width="25 "
          height="25  "
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
            fill="#ec5656"
          />
        </svg>
      </button>
          </div>
          <div class=" fontsize-small py-1">
            <span
                v-if="cartItem.variety && cartItem.variety.final_price"
                class="ml-3"
            >{{
                ((cartItem.discount_price != null
                    ? cartItem.price
                    : cartItem.variety.price) *
                    cartItem.quantity)
                    | price
              }}
              تومان</span
            ><span
              v-if="cartItem.discount_price != null && cartItem.discount_price > 0"
              class="line-through text-color-999"
          >{{ cartItem.variety.price | price }}</span
          >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-sm-block d-none pt-2">
      <div class="d-flex justify-content-between ">
        <div class="text-center d-flex align-items-center pl-3 w-50">
          <router-link
              :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
              class="cart-lists-img w-50 pl-2 d-flex justify-content-end"
          >
            <img
                v-if="mainImage"
                :src="mainImage.url"
                alt=""
                class=" d-block"
            />
            <div
                v-else
                class="bg-cart-lists-img h-100"
                style="width:50px"
            ></div>
          </router-link>
          <div
              class="product-counter pr-1  w-50 text-center border-radius7 my-auto"
          >
            <CartQuantity v-model="cartItem"/>
          </div>
        </div>
        <div class="text-right  text-color-444 w-50">
          <router-link
              :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
              class="mb-1 fontsize-mini weight-bold text-color-666"
          >
            {{
              cartItem.variety
                  ? cartItem.variety.product.title.substr(0, 62)
                  : ""
            }}
            {{
              cartItem.variety && cartItem.variety.product.title.length > 62
                  ? "..."
                  : ""
            }}
          </router-link
          >
          <h6 v-if="getVarietyTitle != null" class="text-color-a5 fontsize13">{{ getVarietyTitle }}</h6>
          <div class=" fontsize-mini  ">
            <span
                v-if="cartItem.variety && cartItem.variety.final_price"
                class="ml-3"
            >{{
                ((cartItem.discount_price != null
                    ? cartItem.price
                    : cartItem.variety.price) *
                    cartItem.quantity)
                    | price
              }}
              تومان</span
            ><span
              v-if="cartItem.discount_price != null"
              class="line-through text-color-999"
          >{{ (cartItem.variety.price * cartItem.quantity) | price }}</span
          >
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import CartQuantity from "@/parts/Front/components/cart/CartQuantity";
import product from "@/parts/Front/components/productDetail/classes/product";
import {getImage} from '@/libraries/functions'

export default {
  name: "CartListItem",
  inject:['mediaQueries'],
  components: {
    CartQuantity,
  },
  props: {
    value: Object,
    index: Number,
  },
  data() {
    return {
      disabled: false,
      cartItem: this.value,
    };
  },
  computed: {
    mainImage() {
      let images = product.getImagesForVariety(this.cartItem.variety, ['size'])
      if (images != "") {
        return images[0];
      } else if (this.cartItem.variety.product.images != '') {
        return this.cartItem.variety.product.images[0];
      }

      return null
    },
    getVarietyTitle() {
      return product.getVarietyTitle(this.cartItem.variety);
    },
  },
  watch: {
    cartItem(newVal) {
      this.$emit("input", newVal);
    },
    value: {
      handler: function (newVal) {
        this.cartItem = newVal;
      },
      deep: true,
    },
  },
  methods: {
    getImage,
    deleteItem() {
      let item = {
        ...this.cartItem,
      };
      window
        .swal({
          title: "محصول از سبد خرید حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: null,
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then(async (willDelete) => {
          if (willDelete) {
            try {
              let response = await this.$store.dispatch(
                "front/deleteCart",
                item
              );
              window.swal({
                icon: "success",
                showConfirmButton: true,
                text: response.data.message,
                button: "باشه",
              });
            } catch (error) {
              window.swal({
                title: "خطا",
                showConfirmButton: true,
                text: error.response.data.message,
                button: "باشه",
              });
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.cart-lists-item a:hover {
  color: unset;
}
</style>
