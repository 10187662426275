<template>
  
<svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.89253 4.78502C3.75003 4.78502 3.60003 4.72502 3.49503 4.62002C3.27753 4.40252 3.27753 4.04252 3.49503 3.82502L6.21753 1.10252C6.43503 0.885017 6.79503 0.885017 7.01253 1.10252C7.23003 1.32002 7.23003 1.68002 7.01253 1.89752L4.29003 4.62002C4.17753 4.72502 4.03503 4.78502 3.89253 4.78502Z" fill="#000"/>
<path d="M14.1075 4.78502C13.965 4.78502 13.8225 4.73252 13.71 4.62002L10.9875 1.89752C10.77 1.68002 10.77 1.32002 10.9875 1.10252C11.205 0.885017 11.565 0.885017 11.7825 1.10252L14.505 3.82502C14.7225 4.04252 14.7225 4.40252 14.505 4.62002C14.4 4.72502 14.25 4.78502 14.1075 4.78502Z" fill="#000"/>
<path d="M15.1575 7.95001C15.105 7.95001 15.0525 7.95001 15 7.95001H14.8275H3C2.475 7.95751 1.875 7.95751 1.44 7.52251C1.095 7.18501 0.9375 6.66001 0.9375 5.88751C0.9375 3.82501 2.445 3.82501 3.165 3.82501H14.835C15.555 3.82501 17.0625 3.82501 17.0625 5.88751C17.0625 6.66751 16.905 7.18501 16.56 7.52251C16.17 7.91251 15.645 7.95001 15.1575 7.95001ZM3.165 6.82501H15.0075C15.345 6.83251 15.66 6.83251 15.765 6.72751C15.8175 6.67501 15.93 6.49501 15.93 5.88751C15.93 5.04001 15.72 4.95001 14.8275 4.95001H3.165C2.2725 4.95001 2.0625 5.04001 2.0625 5.88751C2.0625 6.49501 2.1825 6.67501 2.2275 6.72751C2.3325 6.82501 2.655 6.82501 2.985 6.82501H3.165Z" fill="#000"/>
<path d="M7.31995 13.725C7.01245 13.725 6.75745 13.47 6.75745 13.1625V10.5C6.75745 10.1925 7.01245 9.9375 7.31995 9.9375C7.62745 9.9375 7.88245 10.1925 7.88245 10.5V13.1625C7.88245 13.4775 7.62745 13.725 7.31995 13.725Z" fill="#000"/>
<path d="M10.77 13.725C10.4625 13.725 10.2075 13.47 10.2075 13.1625V10.5C10.2075 10.1925 10.4625 9.9375 10.77 9.9375C11.0775 9.9375 11.3325 10.1925 11.3325 10.5V13.1625C11.3325 13.4775 11.0775 13.725 10.77 13.725Z" fill="#000"/>
<path d="M11.1676 17.0625H6.64506C3.96006 17.0625 3.36006 15.465 3.12756 14.0775L2.07006 7.59C2.01756 7.2825 2.22756 6.9975 2.53506 6.945C2.84256 6.8925 3.12756 7.1025 3.18006 7.41L4.23756 13.89C4.45506 15.2175 4.90506 15.9375 6.64506 15.9375H11.1676C13.0951 15.9375 13.3126 15.2625 13.5601 13.9575L14.8201 7.395C14.8801 7.0875 15.1726 6.885 15.4801 6.9525C15.7876 7.0125 15.9826 7.305 15.9226 7.6125L14.6626 14.175C14.3701 15.6975 13.8826 17.0625 11.1676 17.0625Z" fill="#000"/>
</svg>

</template>

<script>
export default {};
</script>

<style></style>
