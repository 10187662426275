<template>
  <!-- <treeselect
        :searchable="!mediaQueries.mobileSize"
        :clearable="false"
        :options="options"
        noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
        placeholder="تعداد"
        v-model="product.wanted_quantity"
    /> -->
  <div id="productQuantity">
    <button @click="handlerQuantity('+')">+</button>
    <input min="1" type="text" v-model="quantity" />

    <button @click="handlerQuantity('-')" :class="{ disable: quantity == 1 }">
      -
    </button>
  </div>
</template>

<script>
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  inject: ["mediaQueries"],
  name: "ProductSelectQuantity",
  props: {
    value: Object,
  },
  data() {
    return {
      product: this.value,
      quantity: 1,
    };
  },
  watch: {
    value(newVal) {
      this.product = newVal;
    },
    product(newVal) {
      this.$emit("input", newVal);
    },
    quantity(newVa) {
      if (newVa <= 0) this.quantity = 1;
    },
  },
  components: { Treeselect },
  methods: {
    handlerQuantity(flag) {
      if (flag == "+") {
        this.quantity += 1;
      } else if (this.product.wanted_quantity != 1) {
        this.quantity -= 1;
      }
      this.product.wanted_quantity = this.quantity;
      this.$emit("input", this.product);
    },
  },
  computed: {
    options() {
      let options = [];
      for (let i = 1; i <= this.product.getMaxQuantity(); i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },
  },
};
</script>

<style scoped>
div#productQuantity input {
  width: 50px;
  text-align: center;
  border: 1px solid;
  border-radius: 3px;
  height: 30px;
}
div#productQuantity button.disable {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
}
div#productQuantity button {
  width: 30px;
  background: #121314f0;
  color: white;
  font-size: 30px;
  height: 30px;
  transition: all 1s;
  line-height: 24px;
  border-radius: 3px;
}
div#productQuantity {
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: flex-start;
  gap: 8px;
}
</style>
