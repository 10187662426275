<template>
  <div class="product-congratulations">
    <div @click="close" class="c-overlay" :class="{fadeIn: toggle, fadeOut: !toggle}"></div>
    <div class="content">
      <div class="mini-overlay" :class="{fadeIn: toggle, fadeOut: !toggle}"></div>
      <div class="real-content" :class="{bottomToTop: toggle, topToBottom: !toggle}">
        <div class="close-container">
          <div @click="close" class="close"></div>
        </div>
        <img loading="lazy" class="mt-3" :src="require('@/assets/images/added-to-cart.svg')">
        <p class="message mt-4">{{ message }}</p>
        <p class="mt-2">سبد خرید شما بروز شد</p>
        <button @click="close" class="btn continue-btn">ادامه دادن خرید</button>
        <router-link to="/cart" class="btn see-cart-btn">مشاهده سبد خرید</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductCongratulations",
  props: {
    value: Boolean
  },
  data() {
    return {
      toggle: true,
      message: '',
      animationTimeout: '400' // In ms, also change variable in style
    }
  },
  mounted() {
    this.newMessage()
  },
  methods: {
    newMessage() {
      let messages = [
      'آفرین چه انتخاب خوبی!',
        'آفرین به این انتخاب',
        'سلیقه ات حرف نداره',
        'آفرین چه انتخاب خوبی!',
        'چقدر زیبا انتخاب کردی!',
      ];

      this.message =  messages[Math.floor(Math.random() * messages.length)]
    },
    close() {
      if (!this.toggle) {
        return
      }
      this.toggle = false;
      setTimeout(() => {
        this.$emit('input', false)
      }, this.animationTimeout)
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-timeout:400ms;
.product-congratulations {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
}

.c-overlay {
  flex-grow: 1;
  background: black;
  opacity: 0.5;
}

.content {
  position: relative;
  height: 365px;
}

.real-content {
  height: 100%;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
}

.mini-overlay {
  height: 365px;
  width: 100%;
  background: black;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
}


.close {
  position: absolute;
  width: 20%;
  top: -8px;
  height: 3px;
  border-radius: 5px;
  background-color: #b3b3b3;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}

.close-container {
  position: relative;
  width: 100%;
  height: 1px;
}

.message {
  color: var(--color-theme);
}

.continue-btn {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  text-decoration: none;
  display: block;
  width: 91.2%;
  padding: 13px 5px;
  background-color: white;
  border: 1px solid #c1c1c1;
  color: #313131;
  margin: auto;
  margin-bottom: 10px;
}

.see-cart-btn {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  text-decoration: none;
  display: block;
  width: 91.2%;
  padding: 15px 5px;
  background-color: var(--color-theme);
  color: white;
  margin: auto;
}

@keyframes bottomToTopAnimation {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

@keyframes topToBottomAnimation {
  from {
    bottom: 0;
  }
  to {
    bottom: -100%;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.topToBottom {
  animation:$animation-timeout topToBottomAnimation;
}

.bottomToTop {
  animation: $animation-timeout bottomToTopAnimation;
}

.fadeOut {
  animation: $animation-timeout fadeOutAnimation;
}

.fadeIn {
  animation: $animation-timeout fadeInAnimation;
}
</style>


