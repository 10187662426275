<template>
  <div
    class="productFilterModal in-products-filter modal fade mobile-friendly"
    id="productFilterModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
           <div class="d-flex justify-content-center pt-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                      fill="#444"
                    />
                  </svg>
                  <h6 class="mr-2  weight-bold text-color-444">فیلتر جستجو</h6>
                </div>
        </div>
        <div class="modal-body  pb-sm-5 pt-0 pb-3 px-0">
          <form @submit.prevent="productsFilter" class="mb-5">
            <div class="welog-list-side">
              <section
                class="products-list-filter box-shaddow20 px-3 p-sm-3 bg-white mb-3"
              >


                <section class=" p-3 mb-3">
                  <h6 class="mr-2 fontsize15 weight-bold text-color-444 mt-3">
                    عنوان
                  </h6>
                  <input
                    type="text"
                    v-model="filter.title"
                    placeholder="عنوان جستجو را بنویسید"
                    class=" py-2 px-3 border   w-100 fontsize14"
                  />
                </section>
                <section
                  v-if="sizeFilters && sizeFilters.length"
                  class="  box-shaddow20 p-3 bg-white mb-3"
                >
                  <div class="text-right border-bottom mb-3">
                    <h6 class="mr-2 fontsize15 weight-bold text-color-444 mt-3">
                      سایز
                    </h6>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="size-item"
                      v-for="(size, index) in sizeFilters"
                      :key="index"
                    >
                      <label
                        class="text-color-666 pr-2 d-flex align-items-center"
                        v-if="size"
                        style="line-height:1.9"
                      >
                        <span class="fontsize-small pl-1">{{ size.value }}</span>
                        <b-form-checkbox
                          v-model="filter.selected_sizes[index]"
                          class="size-label"
                          :unchecked-value="false"
                        />
                      </label>
                      <b-skeleton
                        v-else
                        class="ml-2 d-inline-block size-skeleton"
                      />
                    </div>
                  </div>
                </section>
                <div class="mt-3 py-2 d-flex">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="x1"
                      v-model="filter.available"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="fontsize13 text-color-666 mr-2">
                    فقط کالاهای موجود
                  </span>
                </div>
              </section>
              <section class=" box-shaddow20 p-3 bg-white mb-3">
                <h6 class="mr-2 fontsize15 weight-bold text-color-444">
                  قیمت ها
                </h6>
                <vue-slider
                  class=" mx-2 my-4"
                  ref="slider"
                  v-model="priceRange"
                  :tooltip="'none'"
                  :max="minMax"
                  :interval="step"
                  direction="rtl"
                >
                </vue-slider>
                <div
                  class="fontsize13 d-flex flex-wrap justify-content-between"
                >
                  <div class="ml-3">
                    <span class="text-color-444 ml-1">از قیمت:</span>
                    <span class="text-color-666" v-if="priceRange[0] != 0"
                      >{{ priceRange[0] | price }} تومان</span
                    >
                    <span class="text-color-666" v-else>0 تومان</span>
                  </div>
                  <div>
                    <span class="text-color-444 ml-1">تا</span>
                    <span class="text-color-666"
                      >{{ priceRange[1] | price }} تومان</span
                    >
                  </div>
                </div>
              </section>
              <div class="px-3">
                <button
                  type="submit"
                  :disabled="disabled"
                  class="fontsize15 weight-bold text-white bg-color-theme py-2 w-100  "
                >
                  اعمال فیلتر
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import closeModalMixin from '@/libraries/mixins/closeModalMixin'
import {BSkeleton, BFormCheckbox} from 'bootstrap-vue'

export default {
  components: {
    BSkeleton, BFormCheckbox
  },
  props: {
    value: Object,
    loading: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [closeModalMixin],
  data() {
    return {
      // filter:{
      //     title:'',
      //     available: 0,
      //     max_price:'',
      //     min_price:'',
      // },
      // disabled: false
      modals: ['productFilterModal'],
      priceRange: [0, 50000],
      step: 1000,
      filter: cloneDeep(this.value),
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        this.filter = cloneDeep(newVal);
      },
      deep: true,
    },
    minMax(newVal) {
      this.$set(this.priceRange, 1, newVal);
    },
  },
  computed: {
    disabled() {
      return this.$store.getters["front/getProductsStatus"];
    },
    minMax() {
      return this.$store.getters["front/getProductsMaxPrice"];
    },
    sizeFilters() {
      if (this.$store.getters["front/getSizeValues"]) {
        let values = this.$store.getters["front/getSizeValues"].values;
        let filters = ["XXS","XXL","XXXL","XXXXL","XXXXXL","XXXXXXL","XXXXXXXL","XXXXXXXXL","0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29",
          "30",
        ];
        return values.filter((value)=>{return !filters.includes(value.value)})
      } else {
        return Array(6).fill(null);
      }
    },
  },
  methods: {
    productsFilter() {
      this.filter.page = 1;
      this.filter.max_price = this.priceRange[1];
      this.filter.min_price = this.priceRange[0];
      this.filter.available
        ? (this.filter.available = 1)
        : (this.filter.available = 0);
      this.$emit("input", this.filter);
      window.$("#productFilterModal").modal("hide");
    },
  },
};
</script>
<style scoped>
.size-label {
  display: inline;
  margin-right: 2px;
}

.size-item {
  display: inline;
  margin-left: 5px;
}

.size-skeleton {
  width: 35px;
}

.color-option {
  background: black;
}
.color-option-img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
</style>
<style>
.vue-slider-dot {
  cursor: pointer;
}

.in-products-filter
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.13rem rgb(222 222 222);
}

.in-products-filter
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #a07f5c;
  background-color: #121314;
}
.in-products-filter .modal-header  {
  justify-content: center;
border-bottom: 1px solid #dee2e6;
}
.in-products-filter .modal-header .close {
  position: absolute;
right: 20px;
top: 15px;
margin: -1rem -1rem -1rem 0;
}
</style>
