<template>
  
<svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.125 1C12.0625 1 15.25 4.1875 15.25 8.125C15.25 12.0625 12.0625 15.25 8.125 15.25C4.1875 15.25 1 12.0625 1 8.125C1 5.35 2.5825 2.95 4.9 1.7725" stroke="#101010" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 16L14.5 14.5" stroke="#101010" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {};
</script>

<style></style>
